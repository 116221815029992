import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { useEffect, useState } from "react";
import { getAdminDashboard } from "api/apiConfig";
import { getBmDashboard } from "api/apiConfig";
import { getFoCenterDashboard } from "api/apiConfig";
import { convertDataToGraph } from "api/constants";
import moment from "moment";
import { getSectorData } from "api/apiConfig";
import { getClusterData } from "api/apiConfig";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const user = JSON.parse(localStorage.getItem("user"));
  const branch = JSON.parse(localStorage.getItem("branches"));

  const { sales, tasks } = reportsLineChartData;
  const [data, setData] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [sectorData, setCenterData] = useState([]);
  const [clusterData, setClusterData] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    const res = await getAdminDashboard();
    if (res?.code == 200) {
      setData(res?.data);
    }
  };
  const getBmData = async (id) => {
    const res = await getBmDashboard(id);
    if (res?.code == 200) {
      setData(res?.data);
    }
  };
  const getFOCenterDashboardData = async (id) => {
    const res = await getFoCenterDashboard(id);
    if (res?.code == 200) {
      setGraphData(convertDataToGraph(res?.data) || []);
    }
  };

  useEffect(() => {
    if (user?.user_role === "Field Officer") {
      navigate("/collection-route");
    }
    if (user.user_role === "Admin") getData();
    else getBmData(branch?.[0]?.branch_id);
  }, []);

  useEffect(() => {
    if (user.user_role === "Admin") getFOCenterDashboardData("");
    else {
      if (branch?.[0]?.branch_id) getFOCenterDashboardData(branch?.[0]?.branch_id);
    }
  }, []);
  useEffect(() => {
    const getSectorGraphData = async (id) => {
      if (!id) return;
      const sectorRes = await getSectorData(id);
      if (sectorRes.code === 200) {
        const newData = sectorRes?.["CENTER_NO_PER_CLUSTER"];
        setData({
          ...data,
          totalCenters: sectorRes?.TOTAL_CENTER,
          totalFo: Object.keys(sectorRes?.CENTER_NO_PER_CLUSTER)?.length,
        });
        setCenterData(convertDataToGraph(newData));
      }
    };

    getSectorGraphData(branch?.[0]?.branch_id);
  }, []);
  useEffect(() => {
    const getClusterGraphData = async (id) => {
      if (!id) return;
      const clusterRes = await getClusterData(id);
      if (clusterRes.code === 200) {
        const data = clusterRes?.["CENTER_NO_PER_CLUSTER"];
        setClusterData(convertDataToGraph(data));
      }
    };

    getClusterGraphData(branch?.[0]?.branch_id);
  }, []);
  return (
    <>
      <MDBox py={3}>
        <Grid container spacing={3}>
          {user.user_role === "Admin" && (
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Total Branch"
                  count={data?.totalBranches || 10}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "than last year",
                  // }}
                />
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total Center"
                count={data?.totalCenters || 0}
                // percentage={{
                //   color: "success",
                //   amount: "+3%",
                //   label: "than last year",
                // }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total FO"
                count={data?.totalFo || 0}
                // percentage={{
                //   color: "success",
                //   amount: "+1%",
                //   label: "than yesterday",
                // }}
              />
            </MDBox>
          </Grid>
          {user.user_role === "Admin" && (
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Total Users"
                  count={data?.totalUsers || 0}
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   label: "Just updated",
                  // }}
                />
              </MDBox>
            </Grid>
          )}
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Number Of Center Day Wise"
                  description=" "
                  date={`${moment().format("MMMM Do YYYY")}`}
                  chart={graphData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="success"
                  title="Number Of Center Per Sector"
                  description=""
                  date={`${moment().format("MMMM Do YYYY")}`}
                  chart={sectorData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="dark"
                  title="Number Of Center Per FO"
                  description=" "
                  date={`${moment().format("MMMM Do YYYY")}`}
                  chart={clusterData}
                />
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Dashboard;
