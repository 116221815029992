import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Circle, InfoWindow, Marker, Polyline } from "@react-google-maps/api";
import CustomMap from "components/GoogleMap";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { markerUrl } from "helper";
import React, { useEffect, useState } from "react";
import earth from "./assets/icon/roatedEarth.gif";
import { toast } from "react-toastify";
import { fetchOldClusterData } from "services";
import { fetchClusterData } from "services";
import { makeAreaData } from "services";
import { fetchAreaWiseData } from "services";
import { computeOffset } from "helper";
import { getBranchList } from "services";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Main = () => {
  const navigate = useNavigate();
  const [branchId, setBranchId] = useState({ branch_id: 176, branch_name: "Gorakhpur" });
  const [branchDetails, setBranchDetails] = useState(null);
  const [oldClusterData, setOldClusterData] = useState(null);
  const [centerDetails, setCenterDetails] = useState(null);
  const [sectorData, setSectorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingArea, setLoadingArea] = useState(false);
  const [centerDetails2, setCenterDetails2] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [highligthCenter, setHighlightCenter] = useState({});
  const [areaCenterDetails, setAreaCenterDetails] = useState(null);
  const [numberOfSector, setNumberOfSector] = useState(6);
  const [foList, setFoList] = useState([]);
  const [selectedFo, setSelectedFo] = useState([]);
  const [modalToggle, setModalToggle] = useState(false);

  const transformData = (data) => {
    const transformedData = [];
    const keys = Object.keys(data);
    keys?.map((item, index) => {
      const url = markerUrl[index];
      transformedData.push(
        ...data[item]?.map((center) => ({
          ...center,
          url: url,
          scaledSize: [30, 30],
        }))
      );
    });
    return transformedData;
  };

  const getBranchData = async () => {
    const result = await getBranchList();
    setBranchList(result);
  };

  useEffect(() => getBranchData(), []);

  const oldClusterDataList = async (branch_id) => {
    await fetchOldClusterData(branch_id).then((result) => {
      setBranchDetails(result?.BRANCH_LAT_LONG?.[0]);
      setOldClusterData(transformData(result?.CENTER_PER_CLUSTER));
      setFoList(result?.FO_LIST);
    });
  };

  const getClusterData = async (branch_id) => {
    setLoading(true);
    const id = branchId?.branch_id || branch_id;
    return await fetchClusterData(id, selectedFo)
      .then((result) => {
        setExcelData(result);
        return result;
      })
      .finally(() => {
        setLoading(false);
        return true;
      });
  };

  const getAreaData = async (id) => {
    setLoadingArea(true);
    const branch_id = id || branchId?.branch_id;
    return await makeAreaData(branch_id, selectedFo)
      .then((result) => {
        setNumberOfSector(result?.fo_count);
        return result;
      })
      .finally(() => {
        setLoadingArea(false);
        return true;
      });
  };

  useEffect(() => {
    const id = branchId?.branch_id;
    if (id) {
      setAreaData([]);
      setLoading(true);
      oldClusterDataList(id);
      getClusterData(id);
      getAreaData(id);
    }
  }, [branchId]);

  const circleOptions = {
    strokeColor: "#db2228",
    strokeOpacity: 1,
    strokeWeight: 3,
    fillColor: "#140109",
    fillOpacity: 0.15,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 40000, // 40 km in meters
    zIndex: 1,
  };

  const onClickHandler = async () => {
    const id = branchId?.branch_id;
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const token = localStorage.getItem("token");
    myHeaders.append("Authorization", `Token ${token}`);

    const raw = JSON.stringify({
      branch_id: id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      "https://dev.finncub.com/route/api/Cluster_fetch_data_with_excel",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setSectorData(transformData(result?.CENTER_PER_CLUSTER)))
      .catch((error) => console.error(error));

    const res2 = await fetchAreaWiseData(id)
      .then((result) => setAreaData(transformData(result?.CENTER_PER_CLUSTER)))
      .finally(() => setLoading(false));
    if (res && res2) {
      return true;
    }
  };

  const handleChange = (e, v) => {
    setFoList([]);
    setBranchId(v);
    setSectorData(null);
    setBranchDetails(null);
    setOldClusterData(null);
    setAreaData(null);
  };

  const onDownloadClick = (e) => {
    const id = branchId?.branch_id;
    const name = `dynamic_cluster_data_${id}.xlsx`;
    if (excelData) {
      const url = window.URL.createObjectURL(
        new Blob([excelData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else {
      toast.error("No data found");
    }
  };

  const numberOfParts = numberOfSector;
  const angleStep = 360 / numberOfParts;
  const radius = 40000;

  const dividingLines = Array.from({ length: numberOfParts }, (_, i) => {
    const angle = angleStep * i;
    return [
      { lat: parseFloat(branchDetails?.[0]), lng: parseFloat(branchDetails?.[1]) },
      computeOffset(
        { lat: parseFloat(branchDetails?.[0]), lng: parseFloat(branchDetails?.[1]) },
        radius,
        angle
      ),
    ];
  });

  const onInputHandler = (e) => {
    const { name, value } = e.target;
    setHighlightCenter({ ...highligthCenter, [name]: value });
  };

  const onCenterPin = () => {
    if (Object.keys(highligthCenter)?.length) {
      const values = Object.values(highligthCenter);
      let newAreaData = areaData?.map((item) => {
        let newItem = item;
        if (values.includes(`${item.center_code}`)) {
          newItem.scaledSize = [45, 45];
          newItem.zIndex = 3;
        } else {
          newItem.scaledSize = [30, 30];
          newItem.zIndex = 0;
        }
        return newItem;
      });

      let newSectorData = sectorData?.map((item) => {
        let newItem = item;
        if (values.includes(`${item.center_code}`)) {
          newItem.scaledSize = [45, 45];
          newItem.zIndex = 3;
        } else {
          newItem.scaledSize = [30, 30];
          newItem.zIndex = 0;
        }
        return newItem;
      });
      setAreaData(newAreaData);
      setSectorData(newSectorData);
    }
  };

  const handleSelectFO = (e) => {
    const value = e.target.value;
    setSelectedFo(value);
  };

  const handleReCluster = async () => {
    setLoading(true);
    getAreaData();
    await getClusterData()
      .then(async (res) => {
        await onClickHandler();
      })
      .finally(() => setLoading(false));
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    setModalToggle(false);
    window.location.reload();
  };

  return (
    <Card style={{ width: "100%", height: "100%", marginTop: 30 }}>
      <MDBox
        mx={2}
        mt={-3}
        p={1}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h6" color="white">
            <p style={{ marginTop: 5 }}>Clustering</p>
          </MDTypography>
          <button
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 10,
              border: "none",
              outline: "none",
              backgroundColor: "#db2228",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => setModalToggle(true)}
          >
            Log Out
          </button>
        </div>
      </MDBox>

      <Box
        sx={{
          margin: 1,
          marginTop: 3,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <FormControl>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={branchList}
              sx={{ width: 250, height: 50 }}
              getOptionLabel={(option) => `${option?.branch_name} (${option?.branch_id})`}
              renderInput={(params) => <TextField {...params} label="Branch" />}
              onChange={handleChange}
              value={branchId}
            />
          </FormControl>

          {areaData?.length > 0 && sectorData?.length > 0 && (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <FormControl sx={{ marginLeft: 4 }}>
                <InputLabel id="demo-multiple-checkbox-label">Select F.O.</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedFo}
                  onChange={handleSelectFO}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  style={{ height: 50, width: 250 }}
                >
                  {foList?.length > 0 &&
                    foList?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedFo?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <button
                onClick={handleReCluster}
                style={{
                  padding: 4,
                  color: "white",
                  backgroundColor: "#db2228",
                  border: "none",
                  height: "40px",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginLeft: 2,
                  cursor: "pointer",
                }}
                disabled={!foList?.length}
              >
                Re-Cluster
              </button>
            </div>
          )}
        </div>

        {areaData?.length > 0 && sectorData?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 5,
              marginRight: 15,
              alignItems: "center",
            }}
          >
            <input
              style={{ width: 150, borderRadius: 10, height: 40, padding: 2 }}
              placeholder="Enter center code"
              name="first"
              onChange={onInputHandler}
            />
            <input
              style={{ width: 150, borderRadius: 10, height: 40, padding: 2 }}
              placeholder="Enter center code"
              name="second"
              onChange={onInputHandler}
            />
            <input
              style={{ width: 150, borderRadius: 10, height: 40, padding: 2 }}
              placeholder="Enter center code"
              name="third"
              onChange={onInputHandler}
            />

            <button
              onClick={onCenterPin}
              style={{
                padding: 4,
                color: "white",
                backgroundColor: "#db2228",
                border: "none",
                height: "40px",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              Search
            </button>
          </div>
        )}
      </Box>

      <Grid container gap={1} justifyContent={"space-between"}>
        {loading || loadingArea ? (
          <Grid
            height={"70vh"}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            md={12}
          >
            <img src={earth} />
          </Grid>
        ) : (
          <>
            <Grid md={sectorData?.length > 0 ? 3.8 : 12} height={"70vh"} mt={2}>
              {sectorData?.length > 0 && (
                <MDTypography variant="h6">
                  <p style={{ marginTop: 3, textAlign: "center" }}>Before Clustering</p>
                </MDTypography>
              )}
              <CustomMap
                zoom={10}
                center={{
                  lat: parseFloat(branchDetails?.[0]),
                  lng: parseFloat(branchDetails?.[1]),
                }}
              >
                <Marker
                  position={{
                    lat: parseFloat(branchDetails?.[0]),
                    lng: parseFloat(branchDetails?.[1]),
                  }}
                  zIndex={2}
                />
                {oldClusterData?.length &&
                  oldClusterData?.map((marker, index) => (
                    <Marker
                      key={index}
                      icon={{
                        url: marker.url,
                      }}
                      position={{
                        lat: parseFloat(marker?.center_lat),
                        lng: parseFloat(marker?.center_long),
                      }}
                      onClick={() => {
                        setCenterDetails(marker);
                      }}
                      zIndex={1}
                      animation={window.google.maps.Animation["DROP"]}
                    />
                  ))}

                {centerDetails && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(centerDetails?.center_lat),
                      lng: parseFloat(centerDetails?.center_long),
                    }}
                    onCloseClick={() => {
                      setCenterDetails(null);
                    }}
                  >
                    <div
                      style={{
                        width: "200px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <h4>center id : {centerDetails?.center_code}</h4>
                      <p>center name : {centerDetails?.center_name}</p>
                      <p>fo Id : {centerDetails?.assigned_to}</p>
                      <br />
                    </div>
                  </InfoWindow>
                )}

                <Circle
                  center={{
                    lat: parseFloat(branchDetails?.[0]),
                    lng: parseFloat(branchDetails?.[1]),
                  }}
                  options={circleOptions}
                />
              </CustomMap>
            </Grid>

            {areaData?.length > 0 && (
              <Grid md={3.8} mt={2} justifyContent={"center"} alignItems={"center"}>
                <MDTypography variant="h6">
                  <p style={{ marginTop: 3, textAlign: "center" }}>Area Clustering</p>
                </MDTypography>

                <CustomMap
                  zoom={10}
                  center={{
                    lat: parseFloat(branchDetails?.[0]),
                    lng: parseFloat(branchDetails?.[1]),
                  }}
                >
                  <Marker
                    position={{
                      lat: parseFloat(branchDetails?.[0]),
                      lng: parseFloat(branchDetails?.[1]),
                    }}
                    zIndex={2}
                  />
                  {areaData?.map((marker, index) => (
                    <Marker
                      key={index}
                      icon={{
                        url: marker.url,
                        scaledSize: new window.google.maps.Size(
                          marker?.scaledSize[0],
                          marker?.scaledSize[1]
                        ),
                      }}
                      position={{
                        lat: parseFloat(marker?.center_lat),
                        lng: parseFloat(marker?.center_long),
                      }}
                      onClick={() => {
                        setAreaCenterDetails(marker);
                      }}
                      zIndex={marker?.zIndex ? marker?.zIndex : 1}
                      animation={window.google.maps.Animation[marker?.zIndex ? "BOUNCE" : "DROP"]}
                    />
                  ))}
                  {areaCenterDetails && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(areaCenterDetails?.center_lat),
                        lng: parseFloat(areaCenterDetails?.center_long),
                      }}
                      onCloseClick={() => {
                        setAreaCenterDetails(null);
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <h4>id : {areaCenterDetails?.center_code}</h4>
                        <p>name : {areaCenterDetails?.center_name}</p>
                        <br />
                      </div>
                    </InfoWindow>
                  )}

                  <Circle
                    center={{
                      lat: parseFloat(branchDetails?.[0]),
                      lng: parseFloat(branchDetails?.[1]),
                    }}
                    options={circleOptions}
                  />
                  {dividingLines?.map((line, index) => (
                    <Polyline
                      key={index}
                      path={line}
                      options={{
                        strokeColor: "#db2228",
                        strokeOpacity: 1.0,
                        strokeWeight: 3,
                      }}
                    />
                  ))}
                </CustomMap>
              </Grid>
            )}
            {sectorData?.length > 0 && (
              <Grid md={3.8} mt={2} justifyContent={"center"} alignItems={"center"}>
                <MDTypography variant="h6">
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <p style={{ marginTop: 3, textAlign: "center" }}>Dynamic Clustering</p>
                    <button
                      onClick={onDownloadClick}
                      style={{
                        marginRight: "50px",
                        backgroundColor: "#db2228",
                        color: "white",
                        outline: "none",
                      }}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Download"}
                    </button>
                  </div>
                </MDTypography>
                <CustomMap
                  zoom={10}
                  center={{
                    lat: parseFloat(branchDetails?.[0]),
                    lng: parseFloat(branchDetails?.[1]),
                  }}
                >
                  <Marker
                    position={{
                      lat: parseFloat(branchDetails?.[0]),
                      lng: parseFloat(branchDetails?.[1]),
                    }}
                    zIndex={2}
                  />
                  {sectorData?.map((marker, index) => (
                    <Marker
                      key={index}
                      icon={{
                        url: marker.url,
                        scaledSize: new window.google.maps.Size(
                          marker?.scaledSize[0],
                          marker?.scaledSize[1]
                        ),
                      }}
                      position={{
                        lat: parseFloat(marker?.center_lat),
                        lng: parseFloat(marker?.center_long),
                      }}
                      onClick={() => {
                        setCenterDetails2(marker);
                      }}
                      zIndex={marker?.zIndex ? marker?.zIndex : 1}
                      animation={window.google.maps.Animation[marker?.zIndex ? "BOUNCE" : "DROP"]}
                    />
                  ))}
                  {centerDetails2 && (
                    <InfoWindow
                      position={{
                        lat: parseFloat(centerDetails2?.center_lat),
                        lng: parseFloat(centerDetails2?.center_long),
                      }}
                      onCloseClick={() => {
                        setCenterDetails2(null);
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 4,
                        }}
                      >
                        <h4>center id : {centerDetails2?.center_code}</h4>
                        <p>center name : {centerDetails2?.center_name}</p>
                        <p>fo Id : {centerDetails2?.assigned_to}</p>
                        <p>cluster : {centerDetails2?.cluster}</p>
                        <br />
                      </div>
                    </InfoWindow>
                  )}

                  <Circle
                    center={{
                      lat: parseFloat(branchDetails?.[0]),
                      lng: parseFloat(branchDetails?.[1]),
                    }}
                    options={circleOptions}
                  />
                </CustomMap>
              </Grid>
            )}
          </>
        )}
        <Grid container md={12} m={1} justifyContent={"center"} alignItems={"center"}>
          <MDButton color="primary" onClick={onClickHandler} disabled={loading}>
            {loading ? "Processing..." : "Process"}
          </MDButton>
        </Grid>
      </Grid>

      <Modal
        open={modalToggle}
        onClose={setModalToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to log out?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, display: "flex", gap: 4, justifyContent: "center" }}
          >
            <button style={{ padding: 2, width: 60 }} onClick={logoutHandler}>
              Yes
            </button>
            <button style={{ padding: 2, width: 60 }} onClick={() => setModalToggle(false)}>
              No
            </button>
          </Typography>
        </Box>
      </Modal>
    </Card>
  );
};

export default Main;
