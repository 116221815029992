const baseUrl = "https://dev.finncub.com/route";

export const fetchBranchData = async () => {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
  };
  return await fetch(`${baseUrl}/api/branch-id-name-list`, requestOptions)
    .then((response) => response.json())
    .then((result) => result?.branch_json)
    .catch((error) => console.error(error));
};

export const fetchOldClusterData = async (branch_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);

  const raw = JSON.stringify({
    branch_id: parseInt(branch_id),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${baseUrl}/api/old_center_fetch_data_with_excel`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const fetchClusterData = async (branch_id, selectedFo) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);

  const raw = JSON.stringify({
    branch_id: branch_id,
    fo_list: selectedFo?.length > 0 ? selectedFo : "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch("https://dev.finncub.com/route/api/Cluster_data_with_excel", requestOptions)
    .then((response) => response.blob())
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const makeAreaData = async (branch_id, selectedFo) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);

  const raw = JSON.stringify({
    branch_id: branch_id,
    fo_list: selectedFo?.length > 0 ? selectedFo : "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${baseUrl}/api/sector-center-with-excel-api`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const fetchAreaWiseData = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const raw = JSON.stringify({
    branch_id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  return await fetch(`${baseUrl}/api/fetch-sector-center-data`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.error(error));
};

export const getBranchList = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Token ${token}`);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(`${baseUrl}/api/branch-id-name-list`, requestOptions)
    .then((response) => response.json())
    .then((result) => result?.branch_json)
    .catch((error) => console.error(error));
};
