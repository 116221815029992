import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import PropTypes from "prop-types";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const libraries = ["places"];

const CustomMap = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBLFOVdYMSLfy9DcAsTIcfH3e9_5kRn4zo",
    libraries,
  });

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <GoogleMap
      mapTypeControl={false}
      mapContainerStyle={mapContainerStyle}
      zoom={props.zoom || 10}
      center={props.center}
      options={{ mapTypeControl: false, streetViewControl: false }}
    >
      {props.children}
    </GoogleMap>
  );
};

CustomMap.propTypes = {
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomMap;
