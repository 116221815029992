import React, { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

const AuthService = createContext();

AuthService.displayName = "AuthServiceContext";

function reducer(state, action) {
  switch (action.type) {
    case "TOKEN": {
      return { ...state, authToken: action.value };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthServiceProvider({ children }) {
  const initialState = {
    isAuth: true,
    authToken: localStorage.getItem("token"),
  };
  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  return <AuthService.Provider value={value}>{children}</AuthService.Provider>;
}

function useAuthServiceProvider() {
  const context = useContext(AuthService);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

AuthServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setToken = (dispatch, value) => dispatch({ type: "TOKEN", value });

export { AuthServiceProvider, useAuthServiceProvider, setToken };
