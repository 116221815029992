export const isGreenish = (color) => {
  // Convert the color to RGB components
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  // Define a threshold to avoid greenish colors
  const greenThreshold = 200;
  const otherThreshold = 100;

  // Return true if the color is too greenish
  return g > greenThreshold && r < otherThreshold && b < otherThreshold;
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color;

  do {
    color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (isGreenish(color));

  return color;
};

export const MAX_WAYPOINTS = 23; // Google Maps API limit is 25, including origin and destination

export const splitWaypoints = (waypoints, maxWaypoints) => {
  const segments = [];
  for (let i = 0; i < waypoints.length; i += maxWaypoints) {
    segments.push(waypoints.slice(i, i + maxWaypoints));
  }
  return segments;
};

export const combineDirections = (responses) => {
  if (!responses.length) return null;

  const result = responses[0];
  for (let i = 1; i < responses.length; i++) {
    const legs = responses[i].routes[0].legs;
    result.routes[0].legs = [...result.routes[0].legs, ...legs];
  }

  return result;
};

export const markerUrl = [
  "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
  "http://maps.google.com/mapfiles/ms/icons/yellow.png",
  "http://maps.google.com/mapfiles/ms/icons/green.png",
  "http://maps.google.com/mapfiles/ms/icons/orange.png",
  "http://maps.google.com/mapfiles/ms/icons/pink.png",
  "http://maps.google.com/mapfiles/ms/icons/red.png",
  "http://maps.google.com/mapfiles/ms/icons/blue.png",
];

export const branchMarker = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";

export function getCurrentDay() {
  // Array of day names in abbreviated form
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  // Get the current date
  const today = new Date();

  // Get the day of the week (0-6) where 0 is Sunday and 6 is Saturday
  const dayIndex = today.getDay();

  // Return the abbreviated day name
  return days[dayIndex];
}

// Function to compute offset for dividing lines
export const computeOffset = (center, radius, angle) => {
  const earthRadius = 6371000; // Earth radius in meters
  const lat = (center.lat * Math.PI) / 180;
  const lng = (center.lng * Math.PI) / 180;
  const bearing = (angle * Math.PI) / 180;

  const lat2 = Math.asin(
    Math.sin(lat) * Math.cos(radius / earthRadius) +
      Math.cos(lat) * Math.sin(radius / earthRadius) * Math.cos(bearing)
  );
  const lng2 =
    lng +
    Math.atan2(
      Math.sin(bearing) * Math.sin(radius / earthRadius) * Math.cos(lat),
      Math.cos(radius / earthRadius) - Math.sin(lat) * Math.sin(lat2)
    );

  return { lat: (lat2 * 180) / Math.PI, lng: (lng2 * 180) / Math.PI };
};
